<template>
  <section class="invoice-add-wrapper">
    <b-form @submit.prevent="createInvoice">
      <b-row class="invoice-add">
        <!-- Col: Left (Invoice Container) -->
        <b-col
          cols="12"
          xl="9"
          md="8"
        >
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                <!-- Header: Left Content -->

                <b-col
                  cols="12"
                  xl="8"
                  class="mb-lg-1"
                >
                  <h6 class="mb-2">
                    Sprzedawca:
                  </h6>

                  <strong>  InFakt Sp z o.o.</strong>
                  <p class="mb-0 pb-0">
                    Kącik 4, 30-549 Kraków
                  </p>

                  <p class="mb-0 pb-0">
                    NIP: 945-212-16-81
                  </p>
                  <p class="mb-0 pb-0">
                    Kraj: Polska
                  </p>

                  <p class="mt-1 pb-0">
                    Podmiot jest czynnym podatnikiem VAT
                  </p>

                  <!-- Selected Client -->
                  <div
                    v-if="invoiceData.customer"
                    class="mt-1"
                  >
                    <h6 class="mb-25">
                      {{ invoiceData.customer.name }}
                    </h6>
                    <b-card-text class="mb-25">
                      {{ invoiceData.customer.company }}
                    </b-card-text>
                    <b-card-text
                      v-show="invoiceData.customer.adres !== undefined"
                      class="mb-25"
                    >
                      {{ invoiceData.customer.adres }},
                      {{ invoiceData.customer.country }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ invoiceData.customer.contact }}
                    </b-card-text>
                    <b-card-text class="mb-0">
                      {{ invoiceData.customer.companyEmail }}
                    </b-card-text>
                  </div>
                </b-col>

                <!-- Header: Right Content -->
                <div class="invoice-number-date mt-md-0 mt-2">
                  <div class="d-flex align-items-center justify-content-md-end mb-1">
                    <h4 class="invoice-title">
                      Invoice
                    </h4>
                    <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="HashIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="invoice-data-id"
                        v-model="invoiceData.id"
                        disabled
                      />
                    </b-input-group>
                  </div>
                  <div class="d-flex align-items-center mb-1">
                    <span class="title">
                      Date:
                    </span>
                    <flat-pickr
                      v-model="invoiceData.issuedDate"
                      class="form-control invoice-edit-input"
                    />
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="title">
                      Due Date:
                    </span>
                    <flat-pickr
                      v-model="invoiceData.dueDate"
                      class="form-control invoice-edit-input"
                    />
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Invoice Client & Payment Details -->
            <b-card-body
              class="invoice-padding pt-0"
            >

              <b-row class="invoice-spacing">

                <!-- waluta -->
                <b-col
                  cols="12"
                  xl="3"
                  class="mb-lg-1"
                >
                  <h6 class="mb-2">
                    Waluta:
                  </h6>
                  <v-select
                    v-model="invoiceData.currency"
                    :options="paymentCurrency"
                  />
                </b-col>

                <!-- termin płatności -->
                <b-col
                  cols="12"
                  xl="3"
                  class="mb-lg-1"
                >
                  <h6 class="mb-2">
                    Sposób płatności
                  </h6>
                  <v-select
                    v-model="invoiceData.payment_method"
                    :options="paymentMethods"
                  />
                </b-col>

              </b-row>

              <b-row class="invoice-spacing">

                <!-- Col: Invoice To -->
                <b-col
                  cols="12"
                  xl="6"
                  class="mb-lg-1"
                >
                  <h6 class="mb-2">
                    Klient:
                  </h6>

                  <!-- Select Client -->
                  <v-select
                    v-model="selectedContractorId"
                    :options="contractorsOptions"
                    label="contractor"
                    input-id="invoice-data-client"
                    itemid="id"
                    :clearable="false"
                    @input="setContractor"
                  >
                    <template #list-header>
                      <li
                        v-b-toggle.sidebar-invoice-add-new-customer
                        class="add-new-client-header d-flex align-items-center my-50"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          size="16"
                        />
                        <span class="align-middle ml-25">Add New Customer</span>
                      </li>
                    </template>
                  </v-select>

                  <!-- Selected Client -->
                  <div
                    v-if="invoiceData.customer"
                    class="mt-1"
                  >
                    <h6 class="mb-25">
                      {{ invoiceData.customer.name }}
                    </h6>
                    <b-card-text class="mb-25">
                      {{ invoiceData.customer.company }}
                    </b-card-text>
                    <b-card-text
                      v-show="invoiceData.customer.adres !== undefined"
                      class="mb-25"
                    >
                      {{ invoiceData.customer.adres }}, {{ invoiceData.customer.country }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ invoiceData.customer.contact }}
                    </b-card-text>
                    <b-card-text class="mb-0">
                      {{ invoiceData.customer.companyEmail }}
                    </b-card-text>
                  </div>
                </b-col>

                <!-- Col: Payment Details -->
                <b-col
                  xl="6"
                  cols="12"
                  class="mt-xl-0 mt-2 justify-content-end d-xl-flex d-block"
                >
                  <div>
                    <h6 class="mb-2">
                      Payment Details:
                    </h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">
                            Nazwa banku:
                          </td>
                          <td>American Bank</td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            Kraj:
                          </td>
                          <td>Polska</td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            IBAN
                          </td>
                          <td>
                            <b-form-input
                              v-model="invoiceData.account_number"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            SWIFT:
                          </td>
                          <td>BR91905</td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            Country:
                          </td>
                          <td>Polska</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Items Section -->
            <b-card-body class="invoice-padding form-item-section">
              <div
                ref="form"
                class="repeater-form"
              >
                <b-row
                  v-for="(item, index) in invoiceData.goods_services"
                  :key="index"
                  ref="row"
                  class="pb-2"
                >

                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">

                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 px-1">
                        <!-- Single Item Form Headers -->
                        <b-col

                          cols="12"
                          lg="3"
                        >
                          Nazwa towaru lub usługi
                        </b-col>
                        <b-col

                          cols="12"
                          lg="2"
                        >
                          GTU
                        </b-col>
                        <b-col

                          cols="12"
                          lg="2"
                        >
                          Ilość
                        </b-col>
                        <b-col

                          cols="12"
                          lg="1"
                        >
                          Cena netto
                        </b-col>
                        <b-col

                          cols="12"
                          lg="1"
                        >
                          Wartość netto
                        </b-col>
                        <b-col

                          cols="12"
                          lg="1"
                        >
                          Stawka VAT
                        </b-col>
                        <b-col

                          cols="12"
                          lg="2"
                        >
                          Wartość brutto w pln
                        </b-col>

                      </b-row>
                      <div class="form-item-action-col" />
                    </div>

                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="3"
                        >
                          <label class="d-inline d-lg-none">Nazwa towaru lub usługi</label>
                          <b-form-input
                            v-model="item.name"
                            type="text"
                            class="mb-2"
                          />
                        </b-col>

                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">GTU</label>
                          <b-form-input
                            v-model="item.GTU"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>

                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Ilość</label>
                          <b-form-input
                            v-model="item.amount"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>

                        <b-col
                          cols="12"
                          lg="1"
                        >
                          <label class="d-inline d-lg-none">Cena netto</label>
                          <b-form-input
                            v-model="item.cost"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>

                        <b-col
                          cols="12"
                          lg="1"
                        >
                          <label class="d-inline d-lg-none">Wartość  netto</label>
                          <b-form-input
                            v-model="item.cost"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>

                        <b-col
                          cols="12"
                          lg="1"
                        >
                          <label class="d-inline d-lg-none">Stawka VAT</label>
                          <b-form-input
                            v-model="item.vat"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>

                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Wartość brutto w pln</label>
                          <b-form-input
                            v-model="item.gross"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>

                      </b-row>

                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="addNewItemInItemForm"
              >
                Dodaj nowy
              </b-button>
            </b-card-body>

            <!-- Invoice Description: Total -->

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">Note: </span>
              <b-form-textarea
                v-model="invoiceData.note"
                :disabled="disabled"
              />
            </b-card-body>
          </b-card>
        </b-col>

        <!-- Right Col: Card -->
        <b-col
          cols="12"
          md="4"
          xl="3"
          class="invoice-actions mt-md-0 mt-2"
        >

          <!-- Action Buttons -->
          <b-card>

            <!-- Button: DOwnload -->
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mb-75"
              block
              :disabled="disabled"
            >
              Preview
            </b-button>

            <!-- Button: Print -->
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              type="submit"
              block
              :disabled="disabled"
            >
              Save
            </b-button>
          </b-card>

          <!-- Status-->

          <b-form-group
            label="Status"
            label-for="payment-method"
          >
            <v-select
              v-model="invoiceData.invoiceStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="invoiceStatus"
              input-id="payment-method"
              class="payment-selector"
              :clearable="false"
            />
          </b-form-group>

          <!-- Język -->

          <b-form-group
            label="Język"
            label-for="payment-method"
          >
            <v-select
              v-model="invoiceData.invoiceLanguage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="invoiceLanguage"
              input-id="payment-method"
              class="payment-selector"
              :clearable="false"
            />
          </b-form-group>

          <!-- Wystawiaj cyklicznie co :  -->

          <b-form-group
            label="Wysatwiaj cyklicznie co :"
            label-for="payment-period"
          >
            <v-select
              v-model="invoiceData.invoicePeriod"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="invoicePeriod"
              input-id="invoicePeriod"
              class="payment-selector"
              :clearable="false"
            />
          </b-form-group>

          <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->

          <!-- Client Notes -->
          <div class="d-flex justify-content-between align-items-center my-1">
            <label for="clientNotes">Client Notes</label>
            <b-form-checkbox
              id="clientNotes"
              :checked="false"
              switch
            />
          </div>

          <!-- Automatyczna windykacja -->
          <div class="d-flex justify-content-between align-items-center">
            <label for="patymentTerms">Automatyczna windykacja</label>
            <b-form-checkbox
              id="patymentTerms"
              :checked="true"
              switch
            />
          </div>

          <!-- Faktura do paragonu -->
          <div class="d-flex justify-content-between align-items-center mt-1">
            <label for="">Faktura do paragonu</label>
            <b-form-checkbox
              id=""
              :checked="true"
              switch
            />
          </div>

          <!-- Mechanizm podzielonej płatności -->
          <div class="d-flex justify-content-between align-items-center mt-1">
            <label for="">Mechanizm podzielonej płatności</label>
            <b-form-checkbox
              id=""
              :checked="false"
              switch
            />
          </div>
        </b-col>
      </b-row>
    </b-form>
    <invoice-sidebar-add-new-customer />
  </section>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import axios from 'axios'
import {
  BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, BFormCheckbox, VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Swal from 'sweetalert2'
import {
  invoiceLanguage,
  invoicePeriod,
  invoiceStatus, paymentCurrency,
  paymentMethods,
  paymentTimePeriod,
} from '@/views/apps/invoice/invoiceDictionaries'
import InvoiceSidebarAddNewCustomer from '../InvoiceSidebarAddNewCustomer.vue'
import invoiceStoreModule from '../invoiceStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    flatPickr,
    vSelect,
    InvoiceSidebarAddNewCustomer,

  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],
  props: ['disabled'],
  data() {
    return {
      paymentMethods,
      invoiceStatus,
      invoicePeriod,
      invoiceLanguage,
      paymentTimePeriod,
      paymentCurrency,
      contractors: [],
      contractorsOptions: [],
      selectedContractorId: null,
      selectedContractor: null,
      invoiceData: {
        goods_services: [],
        seller: {
          name: 'InFakt sp. z o.o.',
          adres: 'Kącik 4, 30-549 Kraków',
          nip: '9452121681',
        },
        customer: {},
        account_number: null,
      },
      nettoValue: 0,
      discount: 0,
      nettoAfterDiscount: 0,
      taxValue: 23,
      bruttoValue: 0,
    }
  },
  mounted() {
    this.initTrHeight()
    this.fetchContractors()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    addNewItemInItemForm() {
      this.invoiceData.goods_services.push({
        name: '',
        GTU: 0,
        quantity: 1,
        netto: 0,
        discount: 0,
        vat: 23,
        brutto_value: 0,
      })
    },
    removeItem(index) {
      this.invoiceData.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    async fetchContractors() {
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      try {
        this.contractors = (await axios.get(`${process.env.VUE_APP_API_URL}Contractor`, config)).data
        this.contractors.forEach(contractor => {
          this.contractorsOptions.push(contractor.Name)
        })
      } catch (e) {
        console.log(e)
      }
    },
    async createInvoice() {
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      const postData = this.invoiceData
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}InvoiceVat`, postData, config)
        await Swal.fire('Dodano nową fakturę VAT', '', 'success')
      } catch (e) {
        await Swal.fire('Coś poszło nie tak', '', 'error')
      }
    },
    setContractor() {
      // eslint-disable-next-line prefer-destructuring
      this.selectedContractor = this.contractors.filter(contractor => contractor.Name === this.selectedContractorId)[0]
      this.invoiceData.customer.name = this.selectedContractor.Name
      this.invoiceData.customer.adres = 'Testowy adres'
      this.invoiceData.customer.nip = '1111111111'
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
