<template>
  <section class="invoice-add-wrapper">
    <span v-if="$route.params.type == 'vat-margin'">
      <vat-margin />
    </span>
    <span v-if="$route.params.type == 'vat'">
      <vat />
    </span>
    <span v-if="$route.params.type == 'advance-payment'">
      <advancePayment />
    </span>
    <span v-if="$route.params.type == 'proforma'">
      <proforma />
    </span>
    <span v-if="$route.params.type == 'accounting-note'">
      <accountingNote />
    </span>
    <span v-if="$route.params.type == 'client-order'">
      <clientOrder />
    </span>
    <span v-if="$route.params.type == 'correction'">
      <correction />
    </span>
    <span v-if="$route.params.type == 'cost'">
      <Cost />
    </span>
    <span v-if="$route.params.type == 'sale'">
      <Sale />
    </span>
    <span v-if="$route.params.type == 'bank-statement'">
      <BankStatement />
    </span>

  </section>

</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import {
  VBToggle,
} from 'bootstrap-vue'

import invoiceStoreModule from '../invoiceStoreModule'

import VatMargin from '../invoice-types/VatMargin.vue'
import Vat from '../invoice-types/Vat.vue'
import AdvancePayment from '../invoice-types/AdvancePayment.vue'
import Proforma from '../invoice-types/Proforma.vue'
import AccountingNote from '../invoice-types/AccountingNote.vue'
import ClientOrder from '../invoice-types/ClientOrder.vue'
import Correction from '../invoice-types/Correction.vue'
import Cost from '../invoice-types/Cost.vue'
import Sale from '../invoice-types/Sale.vue'
import BankStatement from '../invoice-types/BankStatement.vue'

export default {
  components: {
    VatMargin,
    Vat,
    AdvancePayment,
    Proforma,
    AccountingNote,
    ClientOrder,
    Correction,
    Cost,
    Sale,
    BankStatement,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.invoiceData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    removeItem(index) {
      this.invoiceData.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        if (typeof this.$refs.form !== 'undefined') {
          this.trSetHeight(this.$refs.form.scrollHeight)
        }
      })
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const clients = ref([])
    store.dispatch('app-invoice/fetchClients')
      .then(response => { clients.value = response.data })

    const itemFormBlankItem = {
      item: null,
      cost: 0,
      qty: 0,
      description: '',
    }

    const invoiceData = ref({
      id: 5037,
      client: null,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],

      salesPerson: '',
      note: 'It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance projects. Thank You!',
      paymentMethod: null,
    })

    const itemsOptions = [
      {
        itemTitle: 'App Design',
        cost: 24,
        qty: 1,
        description: 'Designed UI kit & app pages.',
      },
      {
        itemTitle: 'App Customization',
        cost: 26,
        qty: 1,
        description: 'Customization & Bug Fixes.',
      },
      {
        itemTitle: 'ABC Template',
        cost: 28,
        qty: 1,
        description: 'Bootstrap 4 admin template.',
      },
      {
        itemTitle: 'App Development',
        cost: 32,
        qty: 1,
        description: 'Native App Development.',
      },
    ]

    const updateItemForm = (index, val) => {
      const { cost, qty, description } = val
      invoiceData.value.items[index].cost = cost
      invoiceData.value.items[index].qty = qty
      invoiceData.value.items[index].description = description
    }

    const paymentMethods = [
      'Bank Account',
      'PayPal',
      'UPI Transfer',
    ]
    const paymentCurrency = [
      'PLN',
      'EUR',

    ]

    return {
      invoiceData,
      clients,
      itemsOptions,
      updateItemForm,
      itemFormBlankItem,
      paymentMethods,
      paymentCurrency,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
